<template>
  <div>
    <ac-module-heading> PA Requests </ac-module-heading>
    <v-card class="card-wrapper">
      <v-card-text>
        <div class="d-flex align-center flex-wrap pb-0">
          <div class="d-flex align-center">
            <ac-company-select @change="onCompanySelect" />
            <ac-input
              v-model="searchQuery"
              :append-icon="searchQuery ? icons.mdiMagnify : ''"
              placeholder="Search"
              outlined
              class="staff-list-search"
              @appendClick="onSearchClick"
              @input="onSearchInput"
            />
          </div>
          <v-spacer />
          <div class="d-flex align-center">
            <v-tooltip v-if="!getPaRequestsTotal" bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <ac-button
                    class="me-3"
                    title="Download CSV"
                    disabled
                    :icon="icons.mdiDownload"
                  ></ac-button
                ></span>
              </template>
              <span class="text-caption text-capitalize font-weight-medium">
                No data available
              </span>
            </v-tooltip>
            <ac-button
              v-else
              class="me-3"
              title="Download CSV"
              :icon="icons.mdiDownload"
              @click="downloadCSV()"
            />

            <v-tooltip v-if="!selectedRecords.length" bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <ac-button title="Download PDF" disabled :icon="icons.mdiDownload"></ac-button
                ></span>
              </template>
              <span class="text-caption text-capitalize font-weight-medium">
                Select PA Request Record
              </span>
            </v-tooltip>
            <ac-button
              v-else
              title="Download PDF"
              :icon="icons.mdiDownload"
              :loading="downloadInProgress"
              @click="downloadPDF()"
            />
          </div>
        </div>
        <div class="d-flex align-center flex-wrap pb-0">
          <v-switch
            v-model="showInactiveUsers"
            label="Show Inactive Users"
            hide-details
            @change="inactiveUserToggle"
          ></v-switch>
        </div>
      </v-card-text>

      <v-data-table
        :headers="headers"
        :items="getPaRequests"
        :page.sync="currentPage"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50, 100],
        }"
        :server-items-length="getPaRequestsTotal"
        @update:items-per-page="onPerPageChange"
        @update:page="onPageChange"
        class="text-no-wrap"
        disable-sort
      >
        <template #[`item.checkbox`]="{ item: { id } }">
          <v-checkbox
            v-model="selectedRecords"
            :value="id"
            hide-details
            class="pa-0 ma-0"
          ></v-checkbox>
        </template>

        <template #[`item.date`]="{ item: { date } }">
          <span>{{ formateDate(date) }}</span>
        </template>

        <template #[`item.actions`]="{ item }">
          <!-- <ac-icon-btn
            btn-class="me-1"
            text="Approve / Reject"
            color="primary"
            :icon="icons.mdiShieldAlert"
          /> -->
          <ac-icon-btn
            btn-class="me-1"
            text="View"
            color="success"
            :icon="icons.mdiEye"
            @click="viewDetails(item)"
          />
          <ac-icon-btn
            btn-class="me-1"
            text="Resend"
            color="secondary"
            :icon="icons.mdiSync"
            disabled
          />
          <ac-icon-btn
            btn-class="me-1 ml-1"
            text="Remove"
            color="error"
            :icon="icons.mdiDelete"
            @click="confirmRemove(item.id)"
          />
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="showConfirmationDialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">Confirm </v-card-title>
        <v-card-text> Are you sure you want to remove this PA Request? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <ac-button title="Cancel" color="error" outlined small @click="resetRemove" />
          <ac-button title="OK" small @click="removePaRequest" />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <pa-request-details-modal
      v-model="showDetailsModel"
      :pa-request="recordToView"
      :initial-approver-only="initialApproverOnly"
    ></pa-request-details-modal>
  </div>
</template>

<script>
import { mdiDelete, mdiDownload, mdiEye, mdiMagnify, mdiShieldAlert, mdiSync } from '@mdi/js'
import moment from 'moment-timezone'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    AcButton: () => import('@/components/AcButton'),
    AcInput: () => import('@/components/AcInput'),
    AcTabs: () => import('@/components/AcTabs'),
    AcIconBtn: () => import('@/components/AcIconBtn'),
    AcCompanySelect: () => import('@/components/AcCompanySelect'),
    PaRequestDetailsModal: () => import('@/components/PaDealing/PaRequests/PaRequestDetailsModal'),
  },

  data: () => ({
    icons: {
      mdiDelete,
      mdiDownload,
      mdiEye,
      mdiShieldAlert,
      mdiSync,
      mdiMagnify,
    },
    loading: false,
    downloadInProgress: false,
    showConfirmationDialog: false,
    showDetailsModel: false,
    searchQuery: null,
    headers: [
      { text: '', value: 'checkbox' },
      { text: 'USER NAME', value: 'username' },
      { text: 'DATE', value: 'date' },
      { text: 'TYPE OF TRADE', value: 'tradeType' },
      { text: 'BUY/SELL', value: 'type' },
      { text: 'GTC', value: 'gtc' },
      { text: 'SECURITY', value: 'security' },
      { text: 'STATUS', value: 'status' },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
      },
    ],
    currentPage: 1,
    itemsSkip: 0,
    itemsPerPage: 10,
    showInactiveUsers: false,
    selectedRecords: [],
    recordToDelete: null,
    recordToView: {},
    initialApproverOnly: false,
  }),

  computed: {
    ...mapGetters('app', ['selectedCompany']),
    ...mapGetters('paDealing', ['companyStaff']),
    ...mapGetters('paRequest', ['getPaRequests', 'getPaRequestsTotal']),
  },

  methods: {
    ...mapActions('paRequest', [
      'fetchPaRequests',
      'fetchAllPaRequests',
      'fetchPdfDownloadLink',
      'removePaRequestRecord',
      'fetchCompanyInitialApproverOnly',
    ]),
    ...mapMutations('paRequest', ['REMOVE_PA_REQUESTS']),

    ...mapActions('paDealing', ['fetchCompanyStaff']),

    formateDate(date) {
      if (!date) return null
      return moment(date).format('DD MMM YYYY HH:MM:SS')
    },

    async onCompanySelect(id) {
      this.currentPage = 1
      this.itemsSkip = 0
      this.fetchCompanyStaff(id)
      await this.loadPaRequests(id)
    },
    onSearchClick() {
      this.loadPaRequests(this.selectedCompany)
    },
    onSearchInput() {
      if (!this.searchQuery) this.loadPaRequests(this.selectedCompany)
    },
    async onPerPageChange(val) {
      this.itemsPerPage = val
      await this.loadPaRequests(this.selectedCompany)
    },
    async onPageChange(val) {
      this.itemsSkip = (val - 1) * this.itemsPerPage
      await this.loadPaRequests(this.selectedCompany)
    },
    async inactiveUserToggle() {
      await this.loadPaRequests(this.selectedCompany)
    },

    async loadPaRequests(companyId) {
      this.loading = true
      this.selectedRecords = []

      const payload = {
        limit: this.itemsPerPage,
        skipData: this.itemsSkip,
        showInactiveUsers: this.showInactiveUsers,
        search: this.searchQuery,
      }
      await this.fetchPaRequests({ companyId, payload })

      this.loading = false
    },

    async downloadCSV() {
      const companyId = this.selectedCompany
      const payload = {
        limit: this.getPaRequestsTotal,
        skipData: 0,
        showInactiveUsers: this.showInactiveUsers,
        search: this.searchQuery,
      }

      const response = await this.fetchAllPaRequests({ companyId, payload })
      const { success, data, message } = response

      if (success) {
        this.AcNotify({
          type: 'info',
          message:
            'Please check your downloads folder for a file named <strong>trade-requests.csv</strong>',
          position: 'bottom left',
        })

        const sm = data.map(record => [
          record.username,
          record.date,
          record.tradeType,
          record.type,
          record.gtc,
          record.quantity,
          record.security,
          record.status,
          record.requestorComment,
          record.approverComment,
        ])
        sm.unshift([
          'User Name',
          'Date',
          'Type of Trade',
          'Buy/Sell',
          'GTC Order',
          'Quantity',
          'Security',
          'Status',
          'Requestor Comment',
          'Approver Comment',
        ])
        this.exportToCsv('trade-requests.csv', sm)
      } else {
        this.AcNotify({
          type: 'error',
          message,
          position: 'bottom',
        })
      }
    },
    async downloadPDF() {
      this.downloadInProgress = true

      const { success, message } = await this.fetchPdfDownloadLink(this.selectedRecords)

      this.downloadInProgress = false

      const notificationType = success ? 'info' : 'error'
      const position = success ? 'bottom left' : 'bottom'

      this.AcNotify({
        type: notificationType,
        message,
        position,
      })
    },

    // * table actions
    async viewDetails(record) {
      this.recordToView = record

      const {
        company,
        details: { investmentClass },
      } = record
      const initialApproverOnly = await this.fetchCompanyInitialApproverOnly(company)

      initialApproverOnly.forEach(record => {
        if (record.investmentClass === investmentClass) {
          this.initialApproverOnly = record.initialApproverOnly
        }
      })

      this.showDetailsModel = true
    },
    confirmRemove(id) {
      this.recordToDelete = id
      this.showConfirmationDialog = true
    },
    resetRemove() {
      this.recordToDelete = null
      this.showConfirmationDialog = false
    },
    async removePaRequest() {
      this.showConfirmationDialog = false

      const { success, message } = await this.removePaRequestRecord(this.recordToDelete)

      if (success) {
        await this.loadPaRequests(this.selectedCompany)
      }

      const notificationType = success ? 'success' : 'error'
      const position = 'bottom'
      this.AcNotify({
        type: notificationType,
        message,
        position,
      })
    },
  },
  async mounted() {
    await this.loadPaRequests(this.selectedCompany)
  },
}
</script>

<style lang="scss" scoped>
.staff-list-search {
  max-width: 16rem;
}
</style>
