import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ac-module-heading',[_vm._v(" PA Requests ")]),_c(VCard,{staticClass:"card-wrapper"},[_c(VCardText,[_c('div',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('div',{staticClass:"d-flex align-center"},[_c('ac-company-select',{on:{"change":_vm.onCompanySelect}}),_c('ac-input',{staticClass:"staff-list-search",attrs:{"append-icon":_vm.searchQuery ? _vm.icons.mdiMagnify : '',"placeholder":"Search","outlined":""},on:{"appendClick":_vm.onSearchClick,"input":_vm.onSearchInput},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c(VSpacer),_c('div',{staticClass:"d-flex align-center"},[(!_vm.getPaRequestsTotal)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('ac-button',{staticClass:"me-3",attrs:{"title":"Download CSV","disabled":"","icon":_vm.icons.mdiDownload}})],1)]}}],null,false,711164479)},[_c('span',{staticClass:"text-caption text-capitalize font-weight-medium"},[_vm._v(" No data available ")])]):_c('ac-button',{staticClass:"me-3",attrs:{"title":"Download CSV","icon":_vm.icons.mdiDownload},on:{"click":function($event){return _vm.downloadCSV()}}}),(!_vm.selectedRecords.length)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('ac-button',{attrs:{"title":"Download PDF","disabled":"","icon":_vm.icons.mdiDownload}})],1)]}}],null,false,2746115421)},[_c('span',{staticClass:"text-caption text-capitalize font-weight-medium"},[_vm._v(" Select PA Request Record ")])]):_c('ac-button',{attrs:{"title":"Download PDF","icon":_vm.icons.mdiDownload,"loading":_vm.downloadInProgress},on:{"click":function($event){return _vm.downloadPDF()}}})],1)],1),_c('div',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c(VSwitch,{attrs:{"label":"Show Inactive Users","hide-details":""},on:{"change":_vm.inactiveUserToggle},model:{value:(_vm.showInactiveUsers),callback:function ($$v) {_vm.showInactiveUsers=$$v},expression:"showInactiveUsers"}})],1)]),_c(VDataTable,{staticClass:"text-no-wrap",attrs:{"headers":_vm.headers,"items":_vm.getPaRequests,"page":_vm.currentPage,"loading":_vm.loading,"footer-props":{
        'items-per-page-options': [10, 20, 30, 40, 50, 100],
      },"server-items-length":_vm.getPaRequestsTotal,"disable-sort":""},on:{"update:page":[function($event){_vm.currentPage=$event},_vm.onPageChange],"update:items-per-page":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"item.checkbox",fn:function(ref){
      var id = ref.item.id;
return [_c(VCheckbox,{staticClass:"pa-0 ma-0",attrs:{"value":id,"hide-details":""},model:{value:(_vm.selectedRecords),callback:function ($$v) {_vm.selectedRecords=$$v},expression:"selectedRecords"}})]}},{key:"item.date",fn:function(ref){
      var date = ref.item.date;
return [_c('span',[_vm._v(_vm._s(_vm.formateDate(date)))])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('ac-icon-btn',{attrs:{"btn-class":"me-1","text":"View","color":"success","icon":_vm.icons.mdiEye},on:{"click":function($event){return _vm.viewDetails(item)}}}),_c('ac-icon-btn',{attrs:{"btn-class":"me-1","text":"Resend","color":"secondary","icon":_vm.icons.mdiSync,"disabled":""}}),_c('ac-icon-btn',{attrs:{"btn-class":"me-1 ml-1","text":"Remove","color":"error","icon":_vm.icons.mdiDelete},on:{"click":function($event){return _vm.confirmRemove(item.id)}}})]}}],null,true)})],1),_c(VDialog,{attrs:{"width":"500"},model:{value:(_vm.showConfirmationDialog),callback:function ($$v) {_vm.showConfirmationDialog=$$v},expression:"showConfirmationDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 grey lighten-2"},[_vm._v("Confirm ")]),_c(VCardText,[_vm._v(" Are you sure you want to remove this PA Request? ")]),_c(VCardActions,[_c(VSpacer),_c('ac-button',{attrs:{"title":"Cancel","color":"error","outlined":"","small":""},on:{"click":_vm.resetRemove}}),_c('ac-button',{attrs:{"title":"OK","small":""},on:{"click":_vm.removePaRequest}})],1)],1)],1),_c('pa-request-details-modal',{attrs:{"pa-request":_vm.recordToView,"initial-approver-only":_vm.initialApproverOnly},model:{value:(_vm.showDetailsModel),callback:function ($$v) {_vm.showDetailsModel=$$v},expression:"showDetailsModel"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }